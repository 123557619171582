<template id="coaches">
  <b-container fluid>
    <div v-if="waiting" class="text-center">
      <img :src="loadingImg" style="width: 150px" />
      <h4>Loading...</h4>
    </div>
    <div v-if="!waiting">
      <h5 class="mt-2">
        Coaches ({{ total }})
        <b-button v-b-toggle.collapse-1 variant="primary" class="ml-2" size="sm"
          >Filter</b-button
        >
      </h5>
      <b-collapse id="collapse-1" class="mt-2">
        <b-row class="mb-1">
          <b-col sm="2">
            <b-input-group>
              <b-form-input
                type="text"
                v-model="filters.search_name"
                placeholder="Name"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="filtraArchivio"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm="2">
            <b-input-group>
              <b-form-input
                type="text"
                v-model="filters.squadra"
                placeholder="Team"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="filtraArchivio"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm="2">
            <b-form-select v-model="filters.anno" :options="anni">
            </b-form-select>
          </b-col>
        </b-row>
        <b-row
          class="mt-2 mb-1"
          v-if="auth.isUserEnable('opta_coach')"
          align-v="center"
        >
          <b-col sm="2">
            <b-input-group prepend="From">
              <b-form-input type="date" v-model="filters.from"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="2">
            <b-input-group prepend="To">
              <b-form-input type="date" v-model="filters.to"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="3">
            <multiselect
              v-model="filters.opta_teams"
              :multiple="true"
              :options="opta_teams"
              placeholder="Opta teams"
              label="name"
              track-by="id"
            />
          </b-col>
          <b-col sm="2">
            <multiselect
              v-model="filters.opta_leagues"
              :multiple="true"
              :options="opta_leagues"
              placeholder="Opta leagues"
              label="name"
              track-by="id"
            />
          </b-col>
          <b-col sm="2">
            <multiselect
              v-model="filters.opta_clusters"
              :multiple="true"
              :options="opta_clusters"
              placeholder="Opta clusters"
              label="name"
              track-by="id"
            />
          </b-col>
          <b-col sm="1">
            <b-button @click="getSkillsWithFilter"
              ><b-icon icon="search"></b-icon
            ></b-button>
          </b-col>
        </b-row>
      </b-collapse>

      <v-infinite-scroll
        :loading="loading"
        @bottom="nextPage"
        :offset="20"
        style="max-height: 80vh; overflow-y: scroll"
        class="row"
      >
        <b-col
          :sm="auth.isUserEnable('opta_coach') ? '6' : '4'"
          :md="auth.isUserEnable('opta_coach') ? '6' : '4'"
          :lg="auth.isUserEnable('opta_coach') ? '4' : '3'"
          v-for="(coach, index) in allenatori"
          :key="index"
          class="p-0 m-0"
        >
          <coach-card :skills="skills" :coach="coach" />
        </b-col>
        <div v-if="loading" align="center" style="width: 100%">
          <img :src="loadingImg" style="width: 150px" />
        </div>
      </v-infinite-scroll>
    </div>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import CoachCard from "@/components/CoachCard.vue";
import loadingImg from "@/assets/images/loading.gif";
import InfiniteScroll from "v-infinite-scroll";
import Multiselect from "vue-multiselect";
export default {
  components: {
    CoachCard,
    Multiselect,
    "v-infinite-scroll": InfiniteScroll,
  },

  data: function () {
    return {
      waiting: false,
      loading: false,
      allenatori: [],
      total: 0,
      page: 1,
      skills: [],
      filters: {
        search_name: "",
        anno: "",
        squadra: "",
        opta_teams: [],
        opta_leagues: [],
        opta_clusters: [],
        from: null,
        to: null,
      },
      opta_teams: [],
      opta_leagues: [],
      opta_clusters: [],
      loadingImg,
    };
  },

  created: function () {
    if (this.auth.isUserEnable("opta_coach")) {
      this.getSkills();
      this.getOptaTeams();
      this.getOptaLeagues();
      this.getOptaClusters();
    }
  },

  methods: {
    getSkills() {
      this.waiting = true;
      this.$http.get("/opta/team/skills/range").then((response) => {
        this.skills = response.data;
        this.getCoaches();
      });
    },

    getSkillsWithFilter() {
      this.waiting = true;
      this.$http
        .get("/opta/team/skills/range", { params: this.filters })
        .then((response) => {
          this.skills = response.data;
          this.filtraArchivio();
        });
    },

    getOptaTeams() {
      this.$http.get("/opta/teams").then((response) => {
        this.opta_teams = response.data;
      });
    },

    getOptaLeagues() {
      this.$http.get("/opta/leagues").then((response) => {
        this.opta_leagues = response.data;
      });
    },

    getOptaClusters() {
      this.$http.get("/opta/clusters").then((response) => {
        this.opta_clusters = response.data;
      });
    },

    getCoaches() {
      this.$http.get("/scouting/archivio/allenatori").then((response) => {
        this.allenatori = response.data.data;
        this.total = response.data.total;
        this.waiting = false;
      });
    },

    nextPage() {
      if (this.allenatori.length < this.total) {
        this.page++;
        this.loading = true;
        this.$http
          .get("/scouting/archivio/allenatori?page=" + this.page, {
            params: this.filters,
          })
          .then((response) => {
            this.allenatori = this.allenatori.concat(response.data.data);
            this.loading = false;
          });
      }
    },

    filtraArchivio() {
      this.loading = true;
      this.allenatori = [];
      this.page = 1;
      this.$http
        .get("/scouting/archivio/allenatori", { params: this.filters })
        .then((response) => {
          this.allenatori = response.data.data;
          this.total = response.data.total;
          this.loading = false;
          this.waiting = false;
        });
    },
  },

  watch: {
    "filters.anno": function () {
      this.filtraArchivio();
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
    anni: function () {
      var options = [];
      options.push({ value: "", text: "-- Year --" });
      var anno = moment().subtract(80, "years").format("YYYY");
      for (var i = 0; i <= 60; i++) {
        options.push({ value: anno, text: anno });
        anno++;
      }
      return options;
    },
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
