var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"m-1"},[(_vm.auth.isUserEnable('opta_coach'))?_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"1"}},[_c('b-row',_vm._l((_vm.standard_skills),function(skill){return _c('b-col',{key:'st_' + skill.id,staticClass:"p0 pb-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"round_stats",style:('background:' +
              _vm.getBgStat(skill.id, _vm.getValueStat(skill.id)) +
              ';' +
              (_vm.getValueStat(skill.id).length < 4 ? 'font-size: .8rem;' : '')),attrs:{"title":skill.label}},[_vm._v(" "+_vm._s(_vm.getValueStat(skill.id))+" ")])])}),1)],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6","align-self":"center"}},[_c('polar-coach',{attrs:{"index":1,"coach_id":_vm.coach.id,"coach_avatar":_vm.coach.allenatore
            ? _vm.coach.allenatore.avatar_url
            : '/assets/images/agente.png',"skills_fasi":_vm.skills_fasi,"coach_data":_vm.coach_data,"width":230,"height":230}})],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticStyle:{"font-size":"0.8rem","font-weight":"bold"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"flex":"1","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"title":_vm.coach.allenatore
                ? _vm.coach.allenatore.nome + ' ' + _vm.coach.allenatore.cognome
                : (_vm.coach.initial ? _vm.coach.initial : '') + ' ' + _vm.coach.name}},[_vm._v(" "+_vm._s(_vm.coach.allenatore ? _vm.coach.allenatore.nome + " " + _vm.coach.allenatore.cognome : (_vm.coach.initial ? _vm.coach.initial : "") + " " + _vm.coach.name)+" ")])]),_c('b-col',{staticStyle:{"font-size":"0.8rem","min-height":"18px"},attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.coach.last_stat ? _vm.coach.last_stat.team.name : ""))]),_c('b-col',{attrs:{"cols":"12"}},[_c('flag',{staticClass:"mr-2",attrs:{"country":_vm.coach.allenatore && _vm.coach.allenatore.paese_nascita
                ? _vm.coach.allenatore.paese_nascita.sigla
                : '',"size":16,"title":"Country of birth"}}),_c('flag',{staticClass:"mr-2",attrs:{"country":_vm.coach.allenatore && _vm.coach.allenatore.passaporto
                ? _vm.coach.allenatore.passaporto.sigla
                : '',"size":16,"title":"Passport"}})],1)],1),(_vm.auth.isUserEnable('opta_coach'))?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{staticClass:"p-0",staticStyle:{"cursor":"pointer"},attrs:{"cols":"5"},on:{"click":_vm.getRankIndexMacro}},[_c('Progress',{class:_vm.coach.optaIndex && _vm.coach.optaIndex.games < 3 ? 'macro_red' : '',attrs:{"title":"macro","strokeColor":_vm.coach.optaIndex
                ? _vm.bg_opta[_vm.coach.optaIndex.macro_index_color_rank]
                : '#FFFFFF',"value":_vm.coach.optaIndex
                ? Math.round(_vm.coach.optaIndex.macro_index * 100) / 100
                : 0,"radius":25,"strokeWidth":4,"transitionDuration":0}})],1),_c('b-col',{staticClass:"p-0",staticStyle:{"cursor":"pointer"},attrs:{"cols":"7"},on:{"click":_vm.getRankIndexMicro}},[_c('Progress',{class:_vm.coach.optaIndex && _vm.coach.optaIndex.games < 3
                ? 'micro2_red'
                : 'micro2',attrs:{"title":"micro","strokeColor":_vm.coach.optaIndex
                ? _vm.bg_opta[_vm.coach.optaIndex.micro_index_color_rank]
                : '#FFFFFF',"value":_vm.coach.optaIndex
                ? Math.round(_vm.coach.optaIndex.micro_index * 100) / 100
                : 0,"radius":45,"strokeWidth":7,"transitionDuration":0}})],1)],1):_vm._e(),_c('b-modal',{ref:'coachRankIndexMacroModal' + _vm.coach.id,attrs:{"title":'Coaches index ranking: ' + _vm.title_index_rank,"ok-only":"","ok-title":"Close"}},_vm._l((_vm.coaches_index_rank),function(item,index){return _c('b-row',{key:index,staticClass:"p-1",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-center",style:(_vm.coach.id == item.coach.id ? 'color:#FF0000' : ''),attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('b-img',{attrs:{"src":item.coach && item.coach.allenatore
                  ? item.coach.allenatore.avatar_url
                  : '/assets/images/agente.png',"height":"30"}})],1),_c('b-col',{style:(_vm.coach.id == item.coach.id ? 'color:#FF0000' : '')},[_c('router-link',{style:('cursor: pointer;' +
                (_vm.coach.id == item.coach.id ? 'color:#FF0000' : '')),attrs:{"to":{
                name: 'ScoutingViewCoach',
                params: { id: item.coach.id },
              },"target":"_blank"}},[_vm._v(_vm._s(item.coach.allenatore ? item.coach.allenatore.cognome : item.coach.name)+" ")])],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-center",style:('color:#000000;background:' +
                _vm.bg_opta[item.macro_index_color_rank])},[_c('strong',[_vm._v(_vm._s(item.macro_index))])])])],1)}),1),_c('b-modal',{ref:'coachRankIndexMicroModal' + _vm.coach.id,attrs:{"title":'Coaches index ranking: ' + _vm.title_index_rank,"ok-only":"","ok-title":"Close"}},_vm._l((_vm.coaches_index_rank),function(item,index){return _c('b-row',{key:index,staticClass:"p-1",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-center",style:(_vm.coach.id == item.coach.id ? 'color:#FF0000' : ''),attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('b-img',{attrs:{"src":item.coach && item.coach.allenatore
                  ? item.coach.allenatore.avatar_url
                  : '/assets/images/agente.png',"height":"30"}})],1),_c('b-col',{style:(_vm.coach.id == item.coach.id ? 'color:#FF0000' : '')},[_c('router-link',{style:('cursor: pointer;' +
                (_vm.coach.id == item.coach.id ? 'color:#FF0000' : '')),attrs:{"to":{
                name: 'ScoutingViewCoach',
                params: { id: item.coach.id },
              },"target":"_blank"}},[_vm._v(_vm._s(item.coach.allenatore ? item.coach.allenatore.cognome : item.coach.name)+" ")])],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-center",style:('color:#000000;background:' +
                _vm.bg_opta[item.micro_index_color_rank])},[_c('strong',[_vm._v(_vm._s(item.micro_index))])])])],1)}),1)],1)],1):_c('b-row',[_c('b-col',[_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":{
          name: 'ScoutingVideotagCoach',
          params: { id: _vm.coach.id },
        },"tag":"div"}},[_c('avatar',{attrs:{"avatar":_vm.coach.allenatore
              ? _vm.coach.allenatore.avatar_url
              : '/assets/images/agente.png',"id":_vm.coach.id}})],1)],1),_c('b-col',[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticStyle:{"font-size":"0.8rem","font-weight":"bold"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"flex":"1","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"title":_vm.coach.allenatore
                ? _vm.coach.allenatore.nome + ' ' + _vm.coach.allenatore.cognome
                : (_vm.coach.initial ? _vm.coach.initial : '') + ' ' + _vm.coach.name}},[_vm._v(" "+_vm._s(_vm.coach.allenatore ? _vm.coach.allenatore.nome + " " + _vm.coach.allenatore.cognome : (_vm.coach.initial ? _vm.coach.initial : "") + " " + _vm.coach.name)+" ")])]),_c('b-col',{staticStyle:{"font-size":"0.8rem","min-height":"18px"},attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.coach.last_stat ? _vm.coach.last_stat.team.name : ""))]),_c('b-col',{attrs:{"cols":"12"}},[_c('flag',{staticClass:"mr-2",attrs:{"country":_vm.coach.allenatore && _vm.coach.allenatore.paese_nascita
                ? _vm.coach.allenatore.paese_nascita.sigla
                : '',"size":16,"title":"Country of birth"}}),_c('flag',{staticClass:"mr-2",attrs:{"country":_vm.coach.allenatore && _vm.coach.allenatore.passaporto
                ? _vm.coach.allenatore.passaporto.sigla
                : '',"size":16,"title":"Passport"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }